import React from 'react';
import styled from 'styled-components';
import background from '../assets/background.png';

const Container = styled.div`
  width: 100vw;
  margin-top: 60px;
//   padding: 20px;
padding-top: 30px;
padding-bottom: 30px;
  // background: #f3d4bd; // A light, neutral background color
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  //
  border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: Adds a subtle shadow for depth
`;

const Law = styled.div`
  margin-bottom: 15px;
  margin: 15px;
  padding: 15px;
  font-family: 'Outfit', 'Noto Sans', sans-serif;
  // background: #ededed; // A slightly different shade for each law container
  border-radius: 4px;
  // border: 2px solid #3c4c4b; // A border to separate each law container

    &:hover {
        // darker shade of the color]
        background-color: #fce7d7;
    }
`;

const LawTitle = styled.h2`
  font-size: 1.6rem;
  color: #3c4c4b;
  margin-bottom: 5px;
`;

const LawText = styled.p`
  font-size: 1.1rem;
  color: #3c4c4b;
`;

const laws = [
  { title: 'Law of Decentralization', text: 'All physical infrastructure networks must operate on a decentralized model, ensuring no single point of failure or control.' },
  { title: 'Law of Cryptographic Security', text: 'Every transaction and data exchange within the network must be secured through cryptographic methods to ensure integrity and confidentiality.' },
  { title: 'Law of Digital Monitoring', text: 'Continuous, real-time monitoring of the infrastructure must be conducted using digital technologies to ensure efficiency and transparency.' },
  { title: 'Law of Energy Independence', text: 'Participants in the network have the right to achieve and maintain energy independence through personal investment in solar energy resources.' },
  { title: 'Law of Scalable Investment', text: 'Investment opportunities in solar energy must be scalable, allowing participation from individual sovereign investors to larger entities.' },
  // { title: 'Law of Traceability', text: 'All energy units produced, distributed, or consumed must be traceable, ensuring accountability and transparency in the network.' },
  { title: 'Law of Sustainable Practices', text: 'The network must prioritize sustainability, encouraging the use of renewable energy sources and environmentally friendly practices.' },
  { title: 'Law of Open Source and Open Access', text: 'Access to the network should be non-discriminatory and open to all willing participants who comply with the networks rules and regulations. All code must be open source and data must be open access.' },
  { title: 'Law of Regulatory Compliance', text: 'The network must operate within the bounds of relevant local and international laws and regulations, adapting as these evolve.' },
  { title: 'Law of Continuous Innovation', text: 'The network must foster an environment of continuous technological innovation, particularly in areas of renewable energy, digital security, and decentralized infrastructure.' },
];

const Laws = () => {
  return (
    <Container>
      {laws.map((law, index) => (
        <Law key={index}>
          <LawTitle>{law.title}</LawTitle>
          <LawText>{law.text}</LawText>
        </Law>
      ))}
    </Container>
  );
}

export default Laws;
