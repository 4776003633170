import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:50px;
    `;

const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    // width: 100%;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
    `;

const SubHeading = styled.h2`
    font-size: 1.6rem;
    font-weight: 400;
    padding: 10px;
    margin-top: 20px;
    color: #3c4c4b;

    font-family: 'Outfit','Noto Sans', sans-serif;

    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }
    `;

const Paragraph = styled.p`
    font-size: 1.2rem;  
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    width: 50%;
    text-align: center;


    @media (max-width: 768px) {
        width: 80%;
        font-size: 1.1rem;
    }
    `;

    const List = styled.ul`
    font-size: 1.2rem;  
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    width: 50%;
    text-align: center;

    @media (max-width: 768px) {
        width: 80%;
        font-size: 1.1rem;
    }

    `;

    const ListItem = styled.li`
    font-size: 1.2rem;
    font-weight: 400;
    color: #3c4c4b;
    padding: 10px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.1rem;
    }
    `;

    const Linka = styled(Link)`
    text-decoration: none;

    color: #3c4c4b;
    font-weight: 700;
    `


    const Head2 = styled.h2`
    font-size: 2rem;
    font-weight: 300;
    padding: 10px;
    margin-top: 20px;
    color: #3c4c4b;

    font-family: 'Outfit','Noto Sans', sans-serif;

    text-align: center;

    @media (max-width: 768px) {

    }
    `;


const About2 = () => {
    return (
        <Container>
            <Head2>
                Building America's Solar Energy at Homes and Plants.
            </Head2>
            <Paragraph>
                We are a crypto energy startup, and our first goal is to work with the North Carolina Clean Energy Technology Center to help them with their mission to advance a sustainable energy economy by educating, demonstrating and providing support for clean energy technologies, practices, and policies.
<br></br><br></br>
                Learn more about project mountain through our <Linka to="/simul">@interactive-simulation</Linka>.
            </Paragraph>
        </Container>
    )
}

export default About2
