import React, { useEffect } from 'react';
import styled from 'styled-components';

import background from '../assets/background.png';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // padding: 20px;
    flex-direction: column;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TypeformContainer = styled.div`
    padding: 20px;
    width: 50%;
    border-radius: 8px; // Example border radius
    margin: 10px;
    align-self: center;

    @media (max-width: 1298px) {
        width: 60%;
    }

    @media (max-width: 768px) {
        width: 80%;

    }

`;

const JoinTheList = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <TypeformContainer data-tf-live="01HMTAZJ5KVP39FJTN6VDKCXFT"></TypeformContainer>
      </Wrapper>
    </Container>
  );
};

export default JoinTheList;
