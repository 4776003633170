import React from 'react'
import styled from 'styled-components'
import background from '../assets/background.png'
import LandingBanner from '../sections/LandingBanner'
import About from '../sections/About'


const Blog = () => {
  return (
    <div>
      Blog.
    </div>
  )
}

export default Blog
