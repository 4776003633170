import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:50px;
    `;

const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    // width: 100%;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
    `;

const SubHeading = styled.h2`
    font-size: 1.6rem;
    font-weight: 400;
    padding: 10px;
    margin-top: 20px;
    color: #3c4c4b;

    font-family: 'Outfit','Noto Sans', sans-serif;

    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }
    `;

const Paragraph = styled.p`
    font-size: 1.2rem;  
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    width: 50%;
    text-align: center;


    @media (max-width: 768px) {
        width: 80%;
        font-size: 1.1rem;
    }
    `;

    const List = styled.ul`
    font-size: 1.2rem;  
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    width: 50%;
    text-align: center;

    @media (max-width: 768px) {
        width: 80%;
        font-size: 1.1rem;
    }

    `;

    const ListItem = styled.li`
    font-size: 1.2rem;
    font-weight: 400;
    color: #3c4c4b;
    padding: 10px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.1rem;
    }
    `;

const About = () => {
    return (
        <Container>
            <Heading>About</Heading>
            <Paragraph>
                At e42, we're not just an energy company; we are innovators, visionaries, and pioneers committed to revolutionizing the renewable energy sector through the power of blockchain technology. Based in North America, e42 was founded with a singular vision: to create a sustainable, efficient, and globally accessible energy future.
            </Paragraph>
            <SubHeading>Our Mission</SubHeading>
            <Paragraph>
                Our mission is to harness the potential of blockchain technology to transform how renewable energy is generated, distributed, and consumed. We believe in a world where everyone has access to affordable, clean, and sustainable energy, and we are dedicated to making this vision a reality.
            </Paragraph>
            <SubHeading>Our Vision</SubHeading>
            <Paragraph>
                e42 envisions a future where renewable energy and technological innovation work hand in hand to address the world's energy challenges. Our goal is to be the global leader in digital and decentralized power, creating a sustainable energy ecosystem that benefits all.
            </Paragraph>
            <SubHeading>What we do?</SubHeading>
            <Paragraph>
            e42 is pioneering a tokenized energy platform, where we produce and manage 10 million units of renewable power on-chain. Our approach combines:
            </Paragraph>
            <List>
                <ListItem>
                <strong>Renewable Energy Generation</strong>: Utilizing sources like solar, wind, and hydro, we are committed to clean energy production.
                </ListItem>
                <ListItem>
                <strong>Blockchain Technology</strong>: By integrating the energy sector with blockchain, we create a transparent, efficient, and secure system for energy distribution.
                </ListItem>
                <ListItem>
                <strong>Tokenization of Energy</strong>: Each unit of energy is tokenized, enabling a novel way for energy trading and management, democratizing access to energy resources.
                </ListItem>
            </List>
        </Container>
    )
}

export default About
