import React from 'react'
import styled from 'styled-components'
// import front from '../assets/front.png'
// import back from '../assets/back.png'
import farmim from '../assets/farmim.png'
import { useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { Link } from 'react-router-dom'


const NFTSaleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    margin-bottom: 10%;
    width: 100%;
    // background-color: #f5f5f5;

    @media (max-width: 768px) {
        padding-top: 30px;
    }

    `   

    const ImageDisplay = styled.img`
        width: 200px;
        height: auto;
        padding: 50px;
        cursor: pointer;

        @media (max-width: 768px) {
            width: 130px;
        }
    `

    const Heading = styled.h1`
        font-size: 1.6em;
        font-weight: 400;
        margin-top: 100px;
        margin-bottom: 10px;
        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 1.4em;
            margin-top: 0px;
        }
    `

    const Desc = styled.p`
        font-size: 1.2em;
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;
    `

    const Price = styled.p`
        font-size: 1.2em;
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 1.1em;
        }
    `

    const Price2 = styled.p`
    font-size: 1em;
    font-weight: 400;
    margin-top: 10px;
    padding: 0;
    opacity: 0.7;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;

    @media (max-width: 768px) {
        text-align: center;
        font-size: 1.1em;
    }
`

    const Flexer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        // background-color: pink;
        width: 60%;

        @media (max-width: 768px) {
            flex-direction: column;
            width: 80%;
            align-items: center;
            justify-content: center;
        }

    `

    const Heading3 = styled.h3`
        font-size: 1.2em;
        font-weight: 400;

        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 1.1em;
        }

    `

    const Button = styled.button`
        background-color: transparent;
        color: #3c4c4b;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        border: 2.2px solid #3c4c4b;
        border-radius: 25px;
        font-size: 1.2em;
        font-family: 'Outfit', 'Noto Sans', sans-serif;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background-color: #3c4c4b;
            color: white;
    
        }

        @media (max-width: 768px) {
            align-self: center;
            width: 100%;
        }

    `

    const Heading2 = styled.h2`
        font-size: 1.4em;
        font-weight: 400;
        margin-bottom: 10px;

        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 1.2em;
        }

    `

    const Linka = styled(Link)`
        text-decoration: none;
        color: #3c4c4b;
        font-weight: 600;
        transition: 0.3s;
        &:hover {
            color: #000;
        }
    `


    const Info = styled.p`
        font-size: 1.2em;
        font-weight: 400;
        margin: 0;
        margin-top: -30px;
        padding: 0;
        color: #3c4c4b;
        font-family: 'Outfit', 'Noto Sans', sans-serif;
        text-align: center;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 1.1em;
        }

    `


const NFTSale = () => {

    // when a user clicks the image, it should flip to the back of the card
    // when a user clicks the back of the card, it should flip to the front of the card

    const [isFlipped, setIsFlipped] = useState(false)

    const navigate = useNavigate()

    const handleClick = () => {
        setIsFlipped(!isFlipped)
    }

    const handleBuy = () => {
        navigate('/nft')
    }


    return (
        <NFTSaleContainer>
            <Flexer>
            {/* <ImageDisplay src={front} alt
            ="front" /> */}
            <div onClick={handleClick}>
            <ImageDisplay src={isFlipped ? farmim : farmim} alt
            ="front" />
            {/* <Info>{isFlipped ? "Invest in Solar Today" : "Invest in Onchain Energy"}</Info> */}
            </div>
            <div>
            <Heading>Build a solar farm near you.</Heading>

            <Heading2>nrgy + NFT</Heading2>
            <Heading3>If you are interested in learning more about our on-chain energy credit mechanism, visit <Linka to="credits">@on-chain credits</Linka></Heading3>
            {/* <Desc>Token ID: 0001</Desc> */}
            <Price>Price: 15000 USDC</Price>
            <Price2>This opportunity is available exclusively to accredited investors within the United States at the moment.</Price2>
            <Button
            onClick={handleBuy}
            >Buy Now</Button>
            </div>
            </Flexer>
        </NFTSaleContainer>
    )
}

export default NFTSale
