import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:50px;
    `;

const Heading = styled.h1`
    font-size: 2rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    width: 80%;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
    `;

const Banner = () => {
  return (
    <Container>
      <Heading>[Meme Alert]<br/><br></br>How many 1 megawatt solar plants do we have to start with to reach 1 billion kWh of energy on chain.</Heading>
    </Container>
  )
}

export default Banner
