import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
import styled from 'styled-components'
import background from '../assets/background.png'

const Container = styled.div`
    display: flex;
    justify-content: center;
    font-family: Outfit, sans-serif;
    flex-direction: column;
    color: #000;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    justify-content: center;

    svg {
        background-color: lightgrey;
    }
`

const Heading = styled.h1`
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 300;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`

const Paragraph = styled.p`
    text-align: center;
    font-size: 1.1rem;
    width: 80%;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`

const Legend = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 80%;
    text-align: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`

const LegendItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    // border: 1px solid #ccc;
    border-radius: 14px;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 1rem;
    // font-weight: bold;
    background-color: ${props => props.color};

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`

const LegendItem2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 400;
    border-radius: 14px;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 1rem;
    // font-weight: bold;
    background-color: ${props => props.color};

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`

const Paragraph2 = styled.p`
    text-align: center;
    font-size: 1.1rem;
    width: 80%;
    margin-top: 60px;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`

const Heading2 = styled.h2`
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 300;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`

const Flexer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    // background-color: blue;
    align-items: center;
    width: 50%;
    margin-left: 5%;

    @media (max-width: 768px) {
        width: 80%;
        margin-left: 10%;
    }
`

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `





const Credits = () => {


    const colors = [
        "lightgreen",    
        "#f3d4bd",
        "lightblue",
        "#c8a8ff",
        "#f7a572",
    ] 
    

    useEffect(() => {


        const svg = d3.select(ContainerRef.current).append('svg')
            .attr('width', 400)
            .attr('height', 600)
            .style('background-color', '#fff')
            .style('border', '2px solid #cdcdcd')
            .style('border-radius', '20px')
            .style('opacity', '1')
            .style('margin-top', '20px')
            .call(d3.zoom().on("zoom", (event) => svg.attr("transform", event.transform)))
            .append("g");
    
        const nodes = [
            { id: 'Farm User', group: 1 },
            { id: 'Cell User', group: 1 },
            { id: 'e42', group: 2 },
            { id: 'Grid', group: 3 },
            { id: 'EPP', group: 4 },
            { id: 'Enterprise', group: 5 },
            { id: 'Home', group: 5 }
        ];
    
        const links = [
            { source: 'Farm User', target: 'e42', type: 'Minting' },
            { source: 'Cell User', target: 'e42', type: 'Minting' },
            { source: 'e42', target: 'Grid', type: 'Transfer' },
            { source: 'Grid', target: 'EPP', type: 'Burning' },
            { source: 'EPP', target: 'Enterprise', type: 'Sale' },
            { source: 'EPP', target: 'Home', type: 'Sale' }
        ];
    
        const groupSpacing = 100; // Space between each group
        const startY = 100; // Starting Y-coordinate

        function calculateY(group) {
            return startY + (group - 1) * groupSpacing;
        }

        const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id(d => d.id).distance(100).strength(1))
            .force('charge', d3.forceManyBody().strength(-300))
            // Apply a forceY for each node based on its group to align them vertically
            .force('y', d3.forceY(d => calculateY(d.group)).strength(1))
            // You might want to apply a weak horizontal force to keep nodes centered horizontally
            .force('x', d3.forceX(400 / 2).strength(0.1));
    
        const link = svg.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(links)
            .enter().append('line')
            .attr('stroke-width', 2)
            .style('stroke', link => {
                switch (link.type) {
                    case 'Minting': return 'green';
                    case 'Transfer': return 'orange';
                    case 'Burning': return 'red';
                    case 'Sale': return 'blue';
                    default: return '#999';
                }
            });

            const node = svg.append('g')
            // check if the node is a user or a token
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(nodes)
            .enter().append('circle')
            .attr('r', 15)
            .attr('fill', d => colors[d.group - 1])
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));


        // if it is the e42 node, make it a square with rounded corners border
            
            

    
        // const node = svg.append('g')
        //     .attr('class', 'nodes')
        //     .selectAll('circle')
        //     .data(nodes)
        //     .enter().append('circle')
        //     .attr('r', 15)
        //     .attr('fill', d => d3.schemeCategory10[d.group+3])
        //     .call(d3.drag()
        //         .on("start", dragstarted)
        //         .on("drag", dragged)
        //         .on("end", dragended));

                    // if it is the e42 node, make it a square with rounded corners border
        // const node = svg.append('g')
        //     .attr('class', 'nodes')
        //     .selectAll('rect')
        //     .data(nodes)
        //     .enter().append('rect')
        //     .attr('width', 100)
        //     .attr('height', 50)
        //     .attr('fill', d => d3.schemeCategory10[d.group+3])
        //     .attr('rx', 10)
        //     .attr('ry', 10)
        //     .call(d3.drag()
        //         .on("start", dragstarted)
        //         .on("drag", dragged)
        //         .on("end", dragended));

        




        
    
        const label = svg.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(nodes)
            .enter().append('text')
            .text(d => d.id)
            .attr('x', 6)
            .attr('y', 3);
    
        simulation.nodes(nodes).on('tick', ticked);
        simulation.force('link').links(links);
    
        function ticked() {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);
    
            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y);
    
            label
                .attr('x', d => d.x)
                .attr('y', d => d.y);
        }
    
        function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }
    
        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }
    
        function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
        }
    
        return () => d3.select(ContainerRef.current).select('svg').remove();
    }, []);
    

    const ContainerRef = React.useRef(null);



    return (
        <>
            {/* <div>
                Credit System
            </div>
            <div>
                <p>
                    A credit can be minted by a farm user,
                    transferred to grid, e42, or a household user,
                    and burned when it is used in exchange for USDC.
                </p>
            </div>
            For the visual representation of the credit system, here are the elements:
            - Farm user - type: user
            - Household user -  type: user
            - Grid - type: grid
            - e42 - type: e42
            - Credit - type: token
            - USDC - type: token
            - Mint - type: action
            - Transfer - type: action
            - Burn - type: action

            Each user is represented in the physical system as a node, and each token is represented as a token.
            The actions are represented as edges between the nodes and the tokens.

            The credit system is a system of nodes and edges, where the nodes are the users and the tokens, and the edges are the actions.
            The credit system is a directed graph, where the nodes are the users and the tokens, and the edges are the actions. */}


            <Container ref={ContainerRef}>
                {/* <svg width={800} height={800} /> */}

                <Heading>on-chain energy credits</Heading>

                <Paragraph>
                    An energy credit is minted by a farm user and cell user through e42, and transferred to the grid. It is then burned when it is used in exchange for USDC from the Energy Purchase Partner. The Energy Purchase Partner is for last-mile energy distribution to the enterprise and home users.
                </Paragraph>
                
                <Paragraph>
                    Each node represents an important stakeholder in the energy credit system. The nodes are detailed in the Node Legend below.
                </Paragraph>

                <Paragraph>
                    Each link represents an action in the energy credit system. The links are detailed in the Link Legend below.
                </Paragraph>


                <Heading2>Flowchart</Heading2>

                <Paragraph>
                    The flowchart below shows the flow of credits in the credit system.
                </Paragraph>
                <Flexer>
                <Div>
                <Paragraph2>
                    Link Legend
                </Paragraph2>

                <Legend>
                    <LegendItem color="green">Mint</LegendItem>
                    <LegendItem color="orange">Transfer</LegendItem>
                    <LegendItem color="red">Burn</LegendItem>
                    <LegendItem color="blue">Sale</LegendItem>
                </Legend>
                </Div><Div>
                <Paragraph2>
                    Node Legend
                </Paragraph2>

                <Legend>
                    <LegendItem2 color="lightgreen">Farm User</LegendItem2>
                    <LegendItem2 color="lightgreen">Cell User</LegendItem2>
                    <LegendItem2 color="#f3d4bd">e42</LegendItem2>
                    <LegendItem2 color="lightblue">Grid</LegendItem2>
                    <LegendItem2 color="#c8a8ff">Energy Purchase Partner</LegendItem2>
                    <LegendItem2 color="#f7a572">Enterprise</LegendItem2>
                    <LegendItem2 color="#f7a572">Home</LegendItem2>
                </Legend>
                </Div></Flexer>
                <Paragraph2>
                    Chart
                    </Paragraph2>

            </Container>


        </>
    )

}

export default Credits
