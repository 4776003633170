import React from 'react';
import myVideo from '../assets/video/ad.mp4';
import styled from 'styled-components';
import thumbnail from '../assets/thumbnailposter.png';

// Styled video container
const VideoContainer = styled.div`
  max-width: 800px; // Set max-width for the video container
  margin: auto; // Center the video container
  border-radius: 15px; // Rounded corners
  overflow: hidden; // Ensure the corners of the video are also rounded
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); // Optional: add a shadow for better aesthetics
  video {
    width: 100%; // Make video responsive
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 1068px) {
    max-width: 700px;
  
  }

`;

const VideoComponent = () => {
  return (
    <VideoContainer>
      <video controls poster={
        thumbnail
      }>
        <source src={myVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
  );
};

export default VideoComponent;
