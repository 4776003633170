import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Container = styled.section`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    `

const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
    `

const SubHeading = styled.h2`
    font-size: 1.5rem;
    padding: 20px;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        width: 80%;
    }
    `

    const Button = styled.button`
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    font-family: 'Outfit','Noto Sans', sans-serif;
    background-color: #3c4c4b;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px;
    cursor: pointer;
    
    &:hover {
        // darker shade of the color]
        background-color: #2a3635;
    }

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
    `

    const Linka = styled(Link)`
    text-decoration: none;
    color: #3c4c4b;
    font-weight: 700;
    `



const CTA = () => {
  return (
    <Container>
      <Heading>Get Started Today</Heading>
      <SubHeading>Join the list and get early access to everything e42. Learn more about our philosophy <Linka to="/laws">@laws</Linka>.</SubHeading>
      <Button>Join the list</Button>
    </Container>
  )
}

export default CTA
