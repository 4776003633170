import React, { useState, useEffect } from 'react';
import nft1 from '../assets/nfts/1.png';
import nft2 from '../assets/nfts/2.png';
import nft3 from '../assets/nfts/3.png';
import nft4 from '../assets/nfts/4.png';
import nft5 from '../assets/nfts/5.png';
import nft6 from '../assets/nfts/6.png';
import nft7 from '../assets/nfts/7.png';
import nft8 from '../assets/nfts/8.png';
import nft9 from '../assets/nfts/9.png';
import nft10 from '../assets/nfts/10.png';
import front from '../assets/front.png';
import back from '../assets/back.png';
import styled from 'styled-components';
import { initOnRamp } from '@coinbase/cbpay-js';
import background from '../assets/background.png';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 100%;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 90px;

    @media (max-width: 768px) {
        padding-top: 30px;
    }
`;

const NFTContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const NFTImage = styled.img`
    width: 200px;
    height: auto;
    border-radius: 10px;

    &:hover {
        transform: scale(1.035);
        transition: 200ms;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        width: 120px;
    }
`;

const NFTInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const NFTId = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
`;

const SweatShirtImage = styled.img`
    width: 200px;
    height: auto;
    margin:20px;
    margin-bottom: 80px;

    @media (max-width: 768px) {
        width: 150px;
    }
`;

const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 300;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }
`;

const Heading2 = styled.h1`
    font-size: 2rem;
    font-weight: 300;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }
`;

const Heading3 = styled.h1`
    font-size: 1rem;
    font-weight: 300;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    margin-top:-20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }
`;

const Banner = styled.p`
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 40px;
    font-family: 'Outfit', 'Noto Sans', sans-serif;

    @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        padding: 20px;
    }
`;

const Desc = styled.p`
    font-size: 1.2rem;
    font-weight: 300;
    color: #3c4c4b;
    width: 50%;
    text-align: center;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    // margin-bottom: 55px;


    @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        padding: 20px;
    }
`;


const Disclaimer2 = styled.p`
    font-size: 0.85rem;
    font-weight: 300;
    // color: #3c4c4b; change to not so dark color
    color: #3c4c4b;
    opacity: 0.7;
    width: 50%;
    text-align: center;

    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;

    margin-top: 20px;
    @media (max-width: 768px) {
        text-align: center;

        font-size: 1rem;
        padding: 20px;
    }
`;

const Disclaimer = styled.p`
    font-size: 0.85rem;
    font-weight: 300;
    // color: #3c4c4b; change to not so dark color
    color: #3c4c4b;
    opacity: 0.7;
    width: 50%;
    text-align: center;

    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;

    margin-top: 20px;
    margin-bottom: 55px;

    @media (max-width: 768px) {
        text-align: center;

        font-size: 1rem;
        padding: 20px;
    }
`;



const Collection = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    margin-bottom: 35px;
    // text-transform: uppercase;
`;

const Flexer = styled.div`
    display: flex;
    flex-direction: row;
`;

const NFT = () => {
    const [nfts, setNfts] = useState([
        { id: "a3r1", image: nft1 },
        { id: "2ser", image: nft2 },
        { id: "ft29", image: nft3 },
        { id: "i3x6", image: nft4 },
        { id: "s0i5", image: nft5 },
        { id: "y7er", image: nft6 },
        { id: "1hd6", image: nft7 },
        { id: "2s3r", image: nft8 },
        { id: "v7s5", image: nft9 },
        { id: "ldu0", image: nft10 },
    ]);

    const navigate = useNavigate();

    const [onrampInstance, setOnrampInstance] = useState(undefined);

    // {
//    "name": "organizations/6d13d096-412d-4d9f-ab5c-40b854805d65/apiKeys/b2cb0cb1-bdbd-41fb-b3f8-8b76449a458a",
//    "principal": "cdef6c55-1cec-548e-8a65-ce0ab96f9935",
//    "principalType": "USER",
//    "publicKey": "-----BEGIN EC PUBLIC KEY-----\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEcRy5DVqDZw2AkeDni6FUPZVk+NHP\n4kd16yzK6WgsF8j4Pqs1ZZM9W62olVHAUA5UkO/5ThSKIuaZm84x8BULqQ==\n-----END EC PUBLIC KEY-----\n",
//    "privateKey": "-----BEGIN EC PRIVATE KEY-----\nMHcCAQEEIFYJ8ys73VUO6/6SVGslKfiCRU/FSFvv3/VEdKuoO6f7oAoGCCqGSM49\nAwEHoUQDQgAEcRy5DVqDZw2AkeDni6FUPZVk+NHP4kd16yzK6WgsF8j4Pqs1ZZM9\nW62olVHAUA5UkO/5ThSKIuaZm84x8BULqQ==\n-----END EC PRIVATE KEY-----\n",
//    "createTime": "2024-02-10T05:54:44.092829740Z",
//    "projectId": "d4d8b4b2-1dab-4477-940f-a1eee6c99cc8",
//    "nickname": "e42-test0-key-20240210055441114Z",
//    "scopes": [],
//    "allowedIps": [],
//    "keyType": "GENERAL_KEY",
//    "enabled": true
// }

useEffect(() => {
    // Correcting the initOnRamp usage to include a callback
    // initOnRamp({
    //     appId: 'e42-test0',
    //     widgetParameters: {
    //         destinationWallets: [
    //             {
    //                 address: '0x43B0E7d4C4B9918e874F1D1C16749a208EA31961',
    //                 blockchains: ['ethereum'],
    //             },
    //         ],
    //     },
    //     onSuccess: () => console.log('Purchase success'),
    //     onExit: () => console.log('User exited'),
    //     onEvent: (event) => console.log('event', event),
    //     experienceLoggedIn: 'popup',
    //     experienceLoggedOut: 'popup',
    //     closeOnExit: true,
    //     closeOnSuccess: true,
    // }, (error, instance) => {
    //     if (error) {
    //         console.error('Error initializing Coinbase Pay:', error);
    //         return;
    //     }
    //     // Assuming instance is correctly received here
    //     // Adjust according to the actual API if different
    //     setOnrampInstance(instance);
    // });
}, []);


    const handleNFTClick = (nftId) => {
        // console.log(`NFT ${nftId} clicked`);
        // onrampInstance?.open();

        navigate('/join');
    };

    return (
        <Container>

            <Heading><b>e42 MOUNTAIN</b> (alpha nft)</Heading>
            <Desc>
                For 15000 USDC, you can invest in e42's first solar farm.
                <br></br><br/>e42 Mountain's NFTs have 25% of the solar farm's* revenue for investing a total of 150000 USDC for 10 units of Mountain NFT i.e, each unit has percentage of 2.5% of the solar farm's revenue for 15000 USDC.**
            </Desc>

            <Disclaimer2>
                *This solar farm will be one of many that e42 will be building in the future. Investing in this solar farm will only give you a percentage of the revenue from this farm. The land parcel (10 acres) is located in the North Carolina and is expected to be operational by the end of Q2 2024.
            </Disclaimer2>

            <Disclaimer>
                **This opportunity is only available to accredited investors in the United States.
            </Disclaimer>
    
            <Heading2>NFTs for Sale</Heading2>
            <Heading3>10 available / 10 total</Heading3>
            <Banner>Every NFT purchase comes with a personalized e42 Power Your Wardrobe (Collection) Sweatshirt</Banner>

        

            <Flexer>
                <div>
                    <SweatShirtImage src={front} alt="front" />
                </div>
                <div>
                    <SweatShirtImage src={back} alt="back" />
                </div>
            </Flexer>
            <Collection>e42 MOUNTAIN NFT</Collection>
            <NFTContainer>
                {nfts.map((nft) => (
                    <NFTInfo key={nft.id} onClick={() => handleNFTClick(nft.id)}>
                        <NFTImage src={nft.image} alt="nft" />
                        <NFTId>{nft.id}</NFTId>
                    </NFTInfo>
                ))}
            </NFTContainer>
        </Container>
    );
};

export default NFT;
