import React from 'react';
import styled from 'styled-components';
import background from '../assets/background.png';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

const Container = styled.section`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Logo = styled.img`
    width: 200px;
    height: auto;
    // linear burn
    // mix-blend-mode: multiply;
    // filter: brightness(0) invert(1)';

    @media (max-width: 768px) {
        width: 150px;
    }
`;


const SubHeading = styled.h2`
    font-size: 2rem;
    font-weight: 300;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        text-align: center;
        padding: 20px;
    }
`

const SubHeading2 = styled.h2`
    font-size: 1.4rem;
    font-weight: 400;
    // font-style: italic;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    margin-top: 10px;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 1.3rem;
        text-align: center;
        padding: 20px;
    }
`

const Heading = styled.h1`
    font-size: 4rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: right;
    width: 100%;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 3rem;
        text-align: center;
    }
`

const Flexer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
    width: 50%;
    text-align: right;
    margin-left: 40px;

    @media (max-width: 768px) {
        width: 80%;
        margin-left: 0;
        text-align: center;
        margin-top: 40px;
    }
`

const Button = styled.button`
    background-color: #3c4c4b;
    color: #fff;
    padding: 10px 20px;
    border: none;
    align-self: flex-end;
    border-radius: 25px;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: 'Outfit','Noto Sans', sans-serif;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: #fff;
        color: #3c4c4b;

    }

    @media (max-width: 768px) {
        align-self: center;
        font-size: 1rem;
    }
`


const LandingBanner = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/join');
    }

    return (
        <Container>
            <Logo src={logo} alt="logo" />
            <Flexer>
                <Heading>e42.io</Heading>
                <SubHeading>on-chain energy
                </SubHeading>

                <SubHeading2>Sustainable energy meets blockchain.</SubHeading2>
                <Button

                    onClick={handleButtonClick}
                >Join Us</Button>
            </Flexer>
        </Container>
    )
}

export default LandingBanner
