import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 35px;
  }
`;

const Heading = styled.h1`
  font-size: 1.4rem;
  font-weight: 400;
  color: #3c4c4b;
  font-family: 'Outfit','Noto Sans', sans-serif;
  text-align: center;
  padding: 20px;

  @media (max-width: 768px) {
    font-size: 1.1;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 1.2rem;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #3c4c4b;
  border-radius: 4px;
  background-color: transparent;
  box-sizing: border-box;
`;

const ReinvestmentPercentage = ({ reinvestment, setReinvestment }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    // Optionally, add validation here
    setReinvestment(value);
  };

  return (
    <Container>
      <Heading>Reinvestment Percentage</Heading>
      <Input
        type="number"
        id="reinvestment"
        name="reinvestment"
        placeholder="Enter the percentage of reinvestment"
        value={reinvestment}
        onChange={handleChange}
      />
    </Container>
  );
};

export default ReinvestmentPercentage;
