import React from 'react'
import LandingBanner from '../sections/LandingBanner'
import About from '../sections/About'
import About2 from '../sections/About2'
import styled from 'styled-components'
import background from '../assets/background.png'
import CTA from '../sections/CTA'
import NFTSale from '../sections/NFTSale'
import Video from '../sections/Video'

const BackgroundContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  width: 100%;
  // position: fixed;
`


const Home = () => {
  return (
    <BackgroundContainer>
      <LandingBanner />
        {/* <About /> */}
        <NFTSale />
        <Video />
        <About2 />
        <CTA />
    </BackgroundContainer>
  )
}

export default Home
