import React from 'react'
import VideoComponent from '../elements/VideoComponent'
import styled from 'styled-components'

const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    margin-bottom: 10%;
    width: 100%;
    // background-color: #f5f5f5;

    @media (max-width: 768px) {
        padding-top: 30px;
        width: 90%;
        padding-left: 5%;
    }

    `

const Video = () => {
  return (
    <Cont>
        <VideoComponent />
    </Cont>
  )
}

export default Video
