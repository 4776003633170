import React from 'react'
import panel from '../assets/panel-dark.png'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:50px;
    `;

    const Icon = styled.img`
    width: 50px;
    height: auto;
    margin: 10px;
    `;

    const Text = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit','Noto Sans', sans-serif;
    margin:0;
    text-align: center;
    `;




const Legend = () => {
  return (
    
    <Container>
        <Icon src={panel} alt="panel" />
        <Text>1 Megawatt Plant</Text>
    </Container>
  )
}

export default Legend
