import React from 'react'
import styled from 'styled-components';
import logo from '../assets/logolight.png';
import {Link} from 'react-router-dom';

const Container = styled.div`
    width:100vw;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3c4c4b;
    position: fixed;
    top: 0;
    z-index: 100;
    
    
`

const Logo = styled.img`
    width: 50px;
    height: auto;
    padding: 20px;
`

const Links = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 20px;
`

const SLink = styled(Link)`
    color: white;
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    padding: 20px;
    font-family: 'Outfit','Noto Sans', sans-serif;

    &:hover {
        color: #f3d4bd;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 10px;
    }
`

const NoStyleLink = styled(Link)`
    text-decoration: none;
`


const NavBar = () => {
  return (
    <Container>
        <NoStyleLink to="/">
      <Logo src={logo} alt="logo" />
        </NoStyleLink>
        <Links>
            {/* <SLink to="/">#</SLink> */}
            {/* <SLink to="/simul">Simul</SLink> */}
            {/* <SLink to="/blog">Blog</SLink> */}
            {/* <SLink to="/laws">Laws</SLink> */}
            {/* <SLink href="/progress">Progress</SLink>
            <SLink href="/team">Team</SLink> */}
        </Links>
    </Container>
  )
}

export default NavBar
