import React from 'react';
import Home from './pages/Home';
import JoinTheList from './sections/JoinTheList';
import NavBar from './elements/NavBar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Simulation from './pages/Simulation';
import Blog from './pages/Blog';
import Laws from './pages/Laws';
import NFT from './pages/NFT';
import Credits from './credits/Credits';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join" element={<JoinTheList />} />
        <Route path="/nft" element={<NFT />} />
        <Route path="/simul" element={<Simulation />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/laws" element={<Laws />} />
        <Route path="/credits" element={<Credits />} />
      </Routes>
    </Router>
  );
}

export default App;
